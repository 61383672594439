.resource {
    background-position: bottom left;
    background-repeat: no-repeat;
    background-color: #E1FBFF;
    padding: 48px 113px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tabGroup {
        display: flex;
        justify-content: space-between;
        width: 600px; }

    .content {
        width: 100%;

        .textGroup {
            p {
                margin-top: 64px;
                font-family: 'Quicksand';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                text-align: justify;
                color: #333333;
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;

                span {
                    color: #2CB5C8; } } }

        .colGroup {
            margin-top: 40px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .resourceCol {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 12px;

                .colTitle {
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    text-align: center;
                    color: #2CB5C8; }

                .colImage {
                    margin-top: 20px; }

                .colDesc {
                    margin-top: 20px;
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: justify;
                    color: #333333; } } } } }


@media (max-width: 1199.98px) {
    // .block_title
    //  // font-size: 24px
    .resource {
        padding: 40px 20px;

        .tabGroup {
            width: 100%;
            justify-content: center;
            .tabItem {
                font-size: 14px;
                width: 50%;
                display: flex;
                justify-content: center; } }

        .textGroup {}

        .colGroup {
            display: flex;
            flex-direction: column; } } }
