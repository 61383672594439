* {
  box-sizing: border-box;
  font-family: 'Quicksand';
  scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

img {
  width: 100%;
  height: auto; }

.block_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 80px 0px;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  color: #2CB5C8;
  font-family: 'Quicksand';
  background-color: #ffffff; }

a, a:visited, a:hover {
  color: #2CB5C8;
  text-decoration: none;
  &:hover {
    text-decoration: underline; } }
