.objectives {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: #E1FBFF;
  justify-content: center;
  text-align: center;

  .objective_title {
    font-family: 'Quicksand';
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
    align-items: center;
    color: #2CB5C8;
    margin-top: 64px; }

  .objective_overview {
    display: flex;
    justify-content: center;
    margin: 40px 80px;

    .objective_big_column {
      width: calc((100%-40px)/3);
      display: flex;
      flex-direction: column;
      align-items: center;
      .overview_title {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px; }
      .overview_image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 207px;
        // padding: 20px
        margin-top: 20px; }
      .overview_list_items {
        margin-top: 60px;
        padding: 15px;
        height: 120px;
        width: 100%;
        display: flex;
        justify-content: center;

        .bold_word {
          font-weight: 700;
          font-style: normal; }

        ul {
          list-style-image: url("../../images/body/objective/custom_list_bullet.svg");

          li {
            text-align: left;
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            padding-left: 10px; } } } }

    .objective_small_column {
      width: 70px;
      img {
        margin-top: 178px; } } }

  .objective_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 64px 113px;
    background: linear-gradient(101.79deg, #2CB5C8 0%, #63D1DF 100%);
    border-radius: 4px;
    padding: 48px;

    ul {
      width: 100%;
      .custom_bullet {
        display: flex;
        img {
          width: 10px;
          height: 25px;
          margin-right: 13px; } }
      list-style: None;
      li {
        top: -10;
        text-align: left;
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #FFFFFF;
        margin-top: 16px;
        width: 100%; } } } }

@media (max-width: 1199.98px) {
  .objectives {
    flex-direction: column;
    padding: 40px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .objective_title {
      display: flex;
      align-items: center;
      text-align: center;
      width: 90%; }

    .objective_overview {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .objective_big_column {
        width: 100%;
        .overview_title {
          font-family: 'Quicksand';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #333333; }
        .overview_image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 246px;
          height: 240px;
          // padding: 20px
          margin-top: 10px;
          margin-bottom: -20px; }

        .overview_list_items {
          padding: 30px;
          display: flex;
          align-items: center;
          ul {
            width: 100%;
            list-style-image: url("../../images/body/objective/custom_list_bullet.svg");
            text-align: justify;
            li {
              font-family: 'Quicksand';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #333333;
              padding-left: 10px; } } } }

      .objective_small_column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 24px;
        img {
          height: 46px;
          width: 60px;
          margin-top: 10px; } } }

    .objective_details {
      width: 100%;
      padding: 20px;
      margin-top: 20px;

      ul {
        padding: 5px; } } } }
