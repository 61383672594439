.acknowledge {
    background-position: bottom left;
    background-repeat: no-repeat;
    padding: 64px 180px;

    .logos {
        width: 1140px;
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 24px;
            .logo {
                width: 267px;
                height: 150px;
                margin-right: 12px;
                margin-left: 12px; } } }
    .content {
        width: 100%;
        p {
            margin-bottom: 24px;
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: justify;
            color: #666666; } }

    img {
        width: 100%;
        height: 364px; } }

@media (max-width: 1199.98px) {
    .acknowledge {
        padding: 40px 20px;

        .logos {
            .row {
                margin-bottom: 0px;
                width: 50%;
                margin-left: -20px;
                .logo {
                    width: 157px;
                    height: 88px;
                    margin: 20px; } } } } }

